<template>
    <div @mouseenter="!isMobile() ? hovered = true : hovered = false" @mouseleave="hovered = false" :title="gameTitle(gameName)" class="card my-3 mx-1" @click.stop="hovered ? playGame() : hovered = true" ref="main">
        <img :alt="gameName" :src="gameImageUrl(gameName)" class="card-img-top">
        <div v-show="hovered" class="position-absolute blackout-container container">
            <div class="row" style="height:70%">
                <div class="col-2 w-75 h-100 info">
                    <div class="title-container">
                        <p class="title"><b>{{localizedData.title_short}}</b></p>
                    </div>
                    <div class="description-container">
                        <p class="description">{{localizedData.tagline}}</p>
                    </div>
                </div>
                <div class="col-1 w-25 h-100">
                    <div class="info-image d-flex align-items-center justify-content-end">
                        <img @click.stop="gotoGame" alt="" :src="getImage('i')">
                    </div>
                </div>
            </div>
            <div class="row" style="height:30%">
                <div class="col w-50 h-100 d-flex align-items-center justify-content-start">
                    <div class="play-button-wrapper">
                        <button class="play-button" @click.stop="playGame()">
                            {{localizedData.cta}}
                        </button>
                    </div>
                </div>
                <div class="col w-50 h-100 d-flex align-items-center justify-content-end">
                    <div class="platforms d-flex align-items-center justify-content-end">
                        <div class="tag" v-for="platform in gamePlatforms[gameName]">
                            <img :alt="platform" class="platform" :title="platform" :src="getImage(platform)" @click.stop="gotoStore(platform)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p class="game-title">{{this.portalData.games[gameName].title_short[this.lang]}}</p>
</template>

<script>
    import portalData from '../../portal.json';

    export default {
        async setup(props) {
            return new Promise(function (res) {
                var image = new Image();
                var images = require.context('./../../assets/banners', false, /\.jpg$/);
                image.src =  images('./' + props.gameName + '.jpg');
                image.onload = function () {
                    res();
                }
            }.bind(this));
        },
        name: "GameCard",
        components: {},
        props: ["gameName"],
        data() {
            return {
                portalData: portalData,
                hovered: false,
                buttonWidth: "120px",
                titleFontSize: "18px",
                baseFontSize: "16px",
                infoImageHeight: "30px",
                imageHeight: "40px"
            }
        },
        mounted() {
            window.addEventListener("resize", this.onResize);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setTimeout(function () {
                this.onResize();
            }.bind(this), 100);
        },
        computed: {
            localizedData() {
                var data = this.portalData.games[this.gameName];

                var result = {};
                Object.keys(data).forEach(propName => {
                    var propValue = data[propName];
                    if (typeof propValue === 'object' && propValue[this.lang]) {
                        result[propName] = propValue[this.lang];
                    } else {
                        result[propName] = propValue;
                    }
                });
                return result;
            },
            lang() {
                return this.$route.params.lang || "en";
            },
            gamePlatforms() {
                var gamesData = this.portalData.games;
                var gamePlatforms = {};
                Object.keys(gamesData).forEach(function (gameName) {
                    gamePlatforms[gameName] = Object.keys(gamesData[gameName].links).sort().reverse().filter(function (platform) {
                        return ['android', 'ios'].includes(platform);
                    });
                });
                return gamePlatforms;
            }
        },
        methods: {
            gotoStore(store) {
               window.open(this.storeLink(store), '_blank');
            },
            gotoGame() {
                this.$router.push({
                    name: 'Game',
                    params: {
                        game: this.gameName,
                        lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined
                    }
                });
            },
            playGame() {
                window.location.href = this.$router.resolve({
                    name: 'Play',
                    params: {
                        game: this.gameName,
                        lang: this.$route.params.lang !== "en" ? this.$route.params.lang : undefined
                    }
                }).href;
            },
            storeLink(store) {
                return this.portalData.games[this.gameName].links[store];
            },
            gameImageUrl(game) {
                var images = require.context('./../../assets/banners', false, /\.jpg$/);
                return images('./' + game + '.jpg');
            },
            gameTitle(game) {
                return this.portalData.games[game].title_long[this.lang];
            },
            getImage(image) {
                var images = require.context('./../../assets/gamecard', false, /\.png$/);
                return images('./' + image + '.png');
            },
            isMobile() {
                return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            },
            onResize() {
                if (this.$refs.main) {
                    var width = this.$refs.main.clientWidth;
                } else {
                    return;
                }
                this.buttonWidth = width / 3 + "px";
                this.baseFontSize = width / (398 / 16) + "px";
                this.titleFontSize = width / (398 / 18) + "px";
                this.infoImageHeight = width / (398 / 30) + "px";
                this.imageHeight = width / (398 / 40) + "px";
            },
        }
    }
</script>

<style scoped>

    a {
        text-decoration: none;
    }

    .blackout-container {
        cursor: default;
        border-radius: 2em;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        width: 100%;
        height: 100%;
    }

    .card {
        border-radius: 2em;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        transition: all .2s ease-in-out;
    }
    .card-img-top {
        border-radius: 2em;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .card:hover {
        cursor: pointer;
        box-shadow: unset;
        transform: scale(1.03);
    }

    .info, .play-button, .platform {
        opacity: 1;
        animation: 0.5s show;
    }

    .info-image img {
        opacity: 0.5;
        animation: 0.5s showWithHalfOpacity;
    }

    @keyframes show {
        0% { opacity: 0; transform: scale(0.95); }
        50% { opacity: 1; }
        100% { transform: scale(1); }
    }

    @keyframes showWithHalfOpacity {
        0% { opacity: 0; transform: scale(0.95); }
        50% { opacity: 0.5; }
        100% { transform: scale(1); }
    }

    .info div {
        padding-left: 10%;
        color: white;
    }

    .title-container {
        position: relative;
        padding-top: 10%;
        height: 50%;
    }

    .title {
        position: absolute;
        bottom: 0;
        font-size: v-bind(titleFontSize);
        margin-bottom: 0.5rem;
    }

    .description-container {
        height: 50%;
    }

    .description {
        font-size: v-bind(baseFontSize);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .info-image {
        margin-top: 25%;
        margin-right: 15%;
    }

    .info-image img {
        opacity: 0.5;
        height: v-bind(infoImageHeight);
    }

    .info-image img:hover {
        opacity: 1;
        cursor: pointer;
    }

    .play-button-wrapper {
        margin-left: 10%;
    }

    .play-button {
        -webkit-border-radius: 10px;
        border-radius: 30px;
        border: none;
        color: white;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
        padding: 6px 15px;
        font-size: v-bind(baseFontSize);
        width: v-bind(buttonWidth);
        background-color: #e50101;
    }

    .play-button:active {
        background-color: #a60101;
    }

    .play-button:hover {
        transform: scale(1.05);
        cursor: pointer;
    }

    .platforms {
        padding-bottom: 6px;
    }

    .tag {
        opacity: 0.5;
        padding: 0 4px;
        margin-right: 15px;
        float: right;
        right: calc(15px * var(--n, 1));
    }

    .tag:hover {
        cursor: pointer;
        opacity: 1;
    }

    .tag:nth-child(1) { --n: 1; }
    .tag:nth-child(2) { --n: 2; }

    .platform {
        height: v-bind(imageHeight);
    }

    .game-title {
        font-size: 22px;
        margin-left: 35px;
    }

    @media (max-width: 350px) {
        .blackout-container {
            border-radius: 1em;
        }

        .card {
            border-radius: 1em;
        }

        .card-img-top {
            border-radius: 1em;
        }
    }
</style>